#map {
	width: 100%;
	height: 400px;
}

.pmp-map-result--item {
	border-top: 2px solid #1e267b;
	margin-top: 50px;
	padding-top: 50px;

	h3 {
		font-size: 22px;
	}

	.opening-hours {
		font-weight: bold;
	}
}

.pmp-map-result--item {
	.distance {
		display: block;
		margin-bottom: 15px;
		margin-top: -15px;
		font-weight: 600;
	}
}

.store-based-results {
	display: none;
}

#pmp-store-locator {
	position: relative;
	.pmp-loading {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 50;
		background-color: white;
		display: none;

		&-inner {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			flex-wrap: wrap;
			font-size: 25px;

			* {
				flex-basis: 100%;
			}

			.pmp-loading-spinner {
				width: 100%;
				font-size: 45px;
				margin-bottom: 20px;
			}
		}
	}
}

.pmp-online-results--item {
	text-align: center;
	margin-bottom: 20px;
	padding-bottom: 10px;

	img {
		width: 100%;
		margin-bottom: 10px;
	}
}

.offline-store-text {
	display: none;
}
